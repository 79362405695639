<template>
  <v-dialog v-model="open" max-width="500px">
    <ConfirmationDialog ref="confirmNominationDialog" />
    <v-card v-if="open" class="nomination-card">
      <v-card-title>
        Nominate {{targetNominationType.text}}
      </v-card-title>
      <v-card-text>
        <EmailTextField
          label="Email"
          v-model="nomination.email"
          :loading="loading"
          @valid="emailIsValid = $event"
        />
        <v-select
          label="Roles"
          :items="allRoles"
          v-model="nomination.roleIds"
          multiple
          item-text="name"
          item-value="id"
          :loading="loading || rolesLoading"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="open = false"
        >Close</v-btn>
        <v-btn
          text
          color="primary"
          @click="openConfirmationNominationDialog"
          :disabled="disableSave"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import {
  dismissAction,
  logAndExtractMessage,
  NOMINATION_TYPE_MANAGER,
} from "@/utils/utils";
import EmailTextField from "@/components/custom-components/EmailTextField";

export default {
  name: "NominationDialog",

  components: {
    EmailTextField,
    ConfirmationDialog: () => import("./ConfirmationDialog.vue"),
  },

  props: {
    pSelectedTeam: Object,
  },

  data: () => ({
    open: false,
    loading: false,
    rolesLoading: false,
    targetNominationType: null,
    nomination: {
      email: "",
      roleIds: [],
    },
    emailIsValid: false,
    allRoles: [],
  }),

  computed: {
    disableSave() {
      return !this.nomination.email || !this.emailIsValid;
    },
  },

  methods: {
    openDialog(targetNominationType) {
      this.loadRoles();
      this.targetNominationType = targetNominationType;
      this.nomination.email = "";
      this.nomination.roleIds = [];
      this.open = true;
    },

    openConfirmationNominationDialog() {
      let text;

      if (this.targetNominationType === NOMINATION_TYPE_MANAGER) {
        text = `The manager is responsible to load and maintain the application for the Team. Once you nominate someone to be the Team Manager they will have 5 days to accept or decline the nomination before it expires.

          Once the manager accepts the role they can not be removed without recreating the team.
        `;
      } else {
        text = `
          A member may access all application data and can create and upload comments to the team.
          Once you nominate someone to be the Team Member the Team Manager will have 5 days to approve or
          decline the nomination before it expires.

          Once the manager approves the new member, the member will have 5 days
          to accept the role before the invitation expires.
        `;
      }

      this.$refs.confirmNominationDialog.openDialog({
        header: "Confirm Nomination",
        text,
        submitText: "Confirm",
        submitColor: "green",
        onSubmit: () => { this.saveNominationAndCloseDialog(); },
      });
    },

    async saveNominationAndCloseDialog() {
      this.loading = true;
      try {
        await this.$store.dispatch("nominationModule/saveNomination", {
          nomination: {
            email: this.nomination.email,
            roles: this.nomination.roleIds,
            nominationType: this.targetNominationType.value,
          },
          teamId: this.pSelectedTeam.id,
        });

        this.open = false;

        if (this.targetNominationType === NOMINATION_TYPE_MANAGER) {
          this.$emit("onReloadData");
        }
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    },

    async loadRoles() {
      this.rolesLoading = true;
      try {
        this.allRoles = await this.$store.dispatch("teamRolesModule/loadAllRoles");
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
      this.rolesLoading = false;
    },
  },
};
</script>
